export const SET_WIDGET_IS_OPEN = 'SET_WIDGET_IS_OPEN';
export const SET_DRAWER_IS_OPEN = 'SET_DRAWER_IS_OPEN';
export const SET_AVATAR_URL = 'SET_AVATAR_URL';
export const SET_PRECHAT_ENABLED = 'SET_PRECHAT_ENABLED';
export const SET_PRECHAT_FORM_OPENED = 'SET_PRECHAT_FORM_OPENED';
export const SET_AGENT_NAME = 'SET_AGENT_NAME';
export const SET_SOCKET_ID = 'SET_SOCKET_ID';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const MUI_FONT_LOADED = 'MUI_FONT_LOADED';
export const SET_RESET_CHAT = 'SET_RESET_CHAT';
export const END_CHAT = 'END_CHAT';
export const SET_SESSION_START_TIME = 'SET_SESSION_START_TIME';
export const FEEDBACK_STATUS = 'FEEDBACK_STATUS';
export const SET_END_CHAT_OPEN_MODEL = 'SET_END_CHAT_OPEN_MODEL';
export const CHAT_ENDED = 'CHAT_ENDED';
export const SET_HIDE_FEEDBACK = 'SET_HIDE_FEEDBACK';
export const SET_ALERT_IS_OPEN = 'SET_ALERT_IS_OPEN';