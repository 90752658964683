/* eslint-disable no-undef */
import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import classes from './DrawerMenu.module.scss';
import { setDrawerIsOpen } from '../../store/actions/general';
import PopOver from '../controls/PopOver/PopOver';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';

const DrawerMenu = ({ restartChatHandler}) => {
  const { drawerIsOpen, muiFontLoaded } = useSelector(state => state.general);
  const isMounted = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, [])

  const handleOpen = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setDrawerIsOpen(drawerIsOpen ? false : true));
  }, [dispatch, drawerIsOpen]);

  const handleClose = useCallback((e) => {
    if (isMounted.current) {
      dispatch(setDrawerIsOpen(false));
    }
  }, [dispatch]);

  const restartChat = useCallback(() => {
    restartChatHandler()
  }, [restartChatHandler]);

  return (
    <div className={classes.DrawerMenu}>
      <button className={classes.IconButton} onClick={handleOpen}>
        <MuiPlaceholder
          isMuiFontLoaded={muiFontLoaded}
          element={<div className={classes.MoreHoriz}>more_horiz</div>}
          width={30}
          height={30}
          backgroundColor={'#e2b744'} />
      </button>
      <PopOver close={handleClose} opened={drawerIsOpen}>
        <ul className={classes.MenuBox}>
          <li className={classes.MenuItem} onClick={restartChat}>
            <div className={classes.MenuItemIcon}>
              <MuiPlaceholder
                  isMuiFontLoaded={muiFontLoaded}
                  element={<span aria-hidden="true">restart_alt</span>}
                  width={0}
                  height={0}
                  backgroundColor={'transparent'} />
            </div>
            <div className="text">
              <Trans i18nKey="drawerMenu.restartChat">
                Restart Chat
              </Trans>
            </div>
          </li>
        </ul>
      </PopOver>
    </div>
  );
};
export default DrawerMenu;
