import React, { useReducer, useState } from 'react';
import classes from './FeedbackModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../controls/Text/Text';
import Typing from '../../containers/Typing/Typing';
import { setFeedbackStatus } from '../../store/actions/general';

const initialState = {
  answers: [-1, -1, -1, -1],
  hoveredDot: [-1, -1, -1, -1],
  feedbackSubmit: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'QUESTION_CHANGE':
      const newAnswers = state.answers.slice();
      newAnswers[action.questionIndex] = action.answerIndex;
      return { ...state, answers: newAnswers };
    case 'HOVER_ENTER':
      const newHoveredDot = state.hoveredDot.slice();
      newHoveredDot[action.questionIndex] = action.index;
      return { ...state, hoveredDot: newHoveredDot };
    case 'HOVER_LEAVE':
      const newHoveredDotLeave = state.hoveredDot.slice();
      newHoveredDotLeave[action.questionIndex] = -1;
      return { ...state, hoveredDot: newHoveredDotLeave };
    default:
      return state;
  }
};

const FeedbackModal = () => {
  const { sessionId, feedbackStatus } = useSelector((state) => state.general);
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchFeedback = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleQuestionChange = (questionIndex, answerIndex) => {
    !feedbackStatus && dispatch({ type: 'QUESTION_CHANGE', questionIndex, answerIndex });
  };

  const handleSendFeedback = () => {
    setIsLoading(true);
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        SessionId: sessionId,
        overallRating: state.answers[0] + 1,
        easeOfInteraction: state.answers[1] + 1,
        solutionProvided: state.answers[2] + 1,
        productPurchase: state.answers[3] + 1,
        comments: feedbackMessage,
      }),
      headers: {
        'x-access-token': window.sutherland_variables.survey.token,
        'Content-Type': 'application/json',
      },
    };

    fetch(
      `${window.sutherland_variables.survey.baseApiUrl}/api/feature/cx-mars-chat/feedback`,
      requestOptions
    ).then(() => {
        dispatchFeedback(setFeedbackStatus(true));
        setIsLoading(false);
      }
    );
  };

  const handleMouseEnter = (questionIndex, index) => {
      !feedbackStatus && dispatch({ type: 'HOVER_ENTER', questionIndex, index });
  };

  const handleMouseLeave = (questionIndex) => {
      !feedbackStatus && dispatch({ type: 'HOVER_LEAVE', questionIndex });
  };

  const renderDots = (questionIndex) => {
    const rating = window.sutherland_variables.env === 'production' ? classes.RatingP : classes.Rating;
    const ratingActive = window.sutherland_variables.env === 'production' ? classes.RatingActiveP : classes.RatingActive;

    return [...Array(5)].map((_, i) => (
      <div
        key={i}
        className={`${
          feedbackStatus ? classes.pointerNone : ''
        } ${
          (state.hoveredDot[questionIndex] >= i &&
            state.hoveredDot[questionIndex] !== -1) ||
          (state.answers[questionIndex] >= i && state.answers[questionIndex] !== -1)
            ? ratingActive
            : rating
        }`}
        onClick={() => handleQuestionChange(questionIndex, i)}
        onMouseEnter={() => handleMouseEnter(questionIndex, i)}
        onMouseLeave={() => handleMouseLeave(questionIndex)}
      />
    ));
  };

  const renderQuestions = () => {
    const questions = [
      'Overall, how would you rate the service you received today?',
      'How easy was it for you to get in touch?',
      'How satisfied were you with the answer or solution we provided?',
      'How likely are you to continue to purchase our products based on the service provided today?',
    ];

    return questions.map((question, index) => (
      <div key={index} className="question-container">
        <div className={classes.Label}>{question}</div>
        <div className="question-dots-container">
          <div className={classes.Ratings}>{renderDots(index)}</div>
        </div>
      </div>
    ));
  };

  const messageHandler = (message) => {
    setFeedbackMessage(message);
  }

  return (
    <div className={classes.FeedbackModalContainer}>
      <div className={classes.FeedbackModalContent}>
        <h2>We'd love your feedback</h2>
        {renderQuestions()}
        <div className={`${feedbackStatus && classes.disableFeedbackMsg} ${classes.feedbackTextbox}`}>
          <Text
              value={feedbackMessage}
              onChange={messageHandler}
              placeholder="Any other suggestions:"
            />
        </div>
        <div className={classes.Buttons}>
          <button
            disabled={!state.answers.some((answer) => answer !== -1) || feedbackStatus}
            className={classes.ButtonFeedback}
            onClick={handleSendFeedback}
          >
            Send Feedback
          </button>
        </div>
        {isLoading && <div className={classes.typingSec}><Typing show={true} mode="dot-loader" typingText="" /></div>}
        {feedbackStatus && <div className={classes.feedbackSubmit}>Thank you for your feedback.</div>}
      </div>
    </div>
  );
};

export default FeedbackModal;
