import React, {useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import MuiPlaceholder from '../../../../components/controls/MuiPlaceholder/MuiPlaceholder';
import fileTransferClassesModule from './FileTransfer.module.scss';
import { patchMessages } from '../../../../store/actions/messages';
function FileTransfer({socket, url, index}) {
  const { muiFontLoaded } = useSelector(state => state.general);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStart, setUploadStart] = useState(false);
  const [transferFinishOrCancel, setTransferFinishOrCancel] = useState(false);
  const dropRef = useRef();
  const fileInputRef = useRef();
  const fileReaderRef = useRef(null);
  const dispatch = useDispatch();

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (fileReaderRef.current) {
      fileReaderRef.current.abort();
    }
    fileInputRef.current.value = '';
    setUploadedFile(null);
    setUploadProgress(0);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFileSelect = () => {
    setUploadProgress(0);
    const file = fileInputRef.current.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (file) {
      setUploadedFile(file);
    }
  };

  const b64toBlob = (base64String, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = () => reject('Error converting file to Base64');
      reader.readAsDataURL(file);
    });
  };

  const uploadFile = async() => {
    setUploadStart(true);
    if (fileReaderRef.current) {
      fileReaderRef.current.abort();
    }

    const base64String = await convertFileToBase64(uploadedFile);
    const contentType = uploadedFile.type;
    const blob = b64toBlob(base64String, contentType);

    const formData = new FormData();
    formData.append('file', blob, uploadedFile.name);
    formData.append("fileNameInput", uploadedFile.name);

    const requestOptions = {
      method:'POST',
      body:formData
    };
    
    setUploadProgress(10); 
    const response = await fetch(url,requestOptions);
    if (response.ok) {
      setUploadProgress(100);
      setTransferFinishOrCancel(true);
    } else {
      socket.emit('file-request-cancel',{});
    }
    const messagesList = {
      index : index,
      key: "parameters",
      value: { fileTransfer: true }
    }
    dispatch(patchMessages(messagesList));
  };

  const cancelUpload = () => {
    if (fileReaderRef.current) {
      fileReaderRef.current.abort();
    }
    fileInputRef.current.value = '';
    setUploadedFile(null);
    setUploadProgress(0);
  };

  const cancelTransfer = () => {
    cancelUpload();
    setTransferFinishOrCancel(true);
    socket.emit('file-request-cancel',{});
    const messagesList = {
      index : index,
      key: "parameters",
      value: { fileTransfer: true }
    }
    dispatch(patchMessages(messagesList));
  };

  return (
    (!transferFinishOrCancel) && (
    <div
      ref={dropRef}
      className={isDragging ? `${fileTransferClassesModule.dragzone_highlight}` : `${fileTransferClassesModule.dragzone}`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <p className={fileTransferClassesModule.transfer_title}>Drag & Drop or <span onClick={() => fileInputRef.current.click()} className={uploadStart?fileTransferClassesModule.disabled:null}>Choose file</span> to upload</p>
      <p className={fileTransferClassesModule.transfer_subtitle}>png, jpg, or pdf</p>
      <input type="file" ref={fileInputRef} className={fileTransferClassesModule.input_file} onChange={handleFileSelect} />
      {uploadedFile && (
        <div>
          <div className={fileTransferClassesModule.file_section}>
            <p>{uploadedFile.name}</p>
            <button onClick={cancelUpload}>
              <MuiPlaceholder
                isMuiFontLoaded={muiFontLoaded}
                element={<div>clear_rounded</div>}
                width={25}
                height={25}
                backgroundColor={'#eeeeee'} />
            </button>
          </div>
          <div className={fileTransferClassesModule.progress_bar_container}>
            <div
              className={fileTransferClassesModule.progress_bar}
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        </div>
      )}
      <div className={fileTransferClassesModule.button_sec}>
        <span onClick={cancelTransfer}>Cancel</span>
        <button disabled = {!uploadedFile || uploadStart} onClick={uploadFile}>Upload</button>
      </div>
    </div>
      )
  );
}

FileTransfer.propTypes = {
};

export default FileTransfer;
