import { 
  SET_USER_ID, 
  SET_FINGERPRINT_ID,
  SET_AGE_GATE_SET,
  SET_AGE_GATE_PASS
} from '../constants/authentication';

const initialState = {
  userId: null,
  fingerprintId: null,
  ageGateSet: false,
  ageGatePass: false
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userId: action.userId
      };

    case SET_FINGERPRINT_ID:
      return {
        ...state,
        fingerprintId: action.fingerprintId
      };

    case SET_AGE_GATE_SET:
      return {
        ...state,
        ageGateSet: action.ageGateSet
      };

    case SET_AGE_GATE_PASS:
      return {
        ...state,
        ageGatePass: action.ageGatePass
      };

    default:
      return state;
  }
};

export default authentication;
