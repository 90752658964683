import { 
  SET_MESSAGES, 
  PATCH_MESSAGE,
  ADD_MESSAGE,
  SET_QUICK_RESPONSES,
  SET_MODE,
  SET_AGENT_STATUS,
  SET_IS_TYPING,
  CANCEL_FILEUPLOADS
} from '../constants/messages';

export const setMessages = (messages) => {
  return (dispatch) => {
    dispatch({
      type: SET_MESSAGES,
      messages: messages
    });
  };
};

export const addMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: ADD_MESSAGE,
      message: message
    });
  };  
};

export const patchMessages = (payload) => {
  return (dispatch) => {
    dispatch({
      type: PATCH_MESSAGE,
      message: payload
    });
  };
};

export const setQuickResponses = (quickResponses) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUICK_RESPONSES,
      quickResponses: quickResponses
    });
  };
};

export const setMode = (mode) => {
  return (dispatch) => {
    dispatch({
      type: SET_MODE,
      mode: mode.toLowerCase()
    });
  };
};

export const setAgentStatus = (status) => {
  return (dispatch) => {
    dispatch({
      type: SET_AGENT_STATUS,
      agentStatus: status
    });
  };
};

export const setIsTyping = (isTyping) => {
  return (dispatch) => {
    dispatch({
      type: SET_IS_TYPING,
      typing: isTyping
    });
  };
};

export const cancelFileUploads = () => {
  return (dispatch) => {
    dispatch({
      type: CANCEL_FILEUPLOADS
    });
  };
};


