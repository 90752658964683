import React, {useCallback} from 'react';
import { Trans } from 'react-i18next';
import classes from './Header.module.scss';
import DrawerMenu from '../DrawerMenu/DrawerMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setWidgetIsOpen } from '../../store/actions/general';
import MuiPlaceholder from '../controls/MuiPlaceholder/MuiPlaceholder';
import { isAgeGateSet, isAgeGatePassed } from '../../store/actions/authentication';
import { setMessages, setQuickResponses, setMode } from '../../store/actions/messages';
import { setResetChat, setDrawerIsOpen, setEndChat, setSessionId, setFeedbackStatus, setIsEndChatModelOpen, setChatEnded, setHideFeedback, setAlertIsOpen } from '../../store/actions/general';

const Header = ({socket}) => {
  const { muiFontLoaded, chatEnded } = useSelector(state => state.general);
  const { ageGateSet, ageGatePass } = useSelector(state => state.authentication);
  const dispatch = useDispatch();

  const endChat = useCallback(() => {
    dispatch(setEndChat(false));
    dispatch(setDrawerIsOpen(false))
    dispatch(setResetChat(true))
    dispatch(setMode("bot"))
    dispatch(setSessionId(''))
    dispatch(setMessages([]))
    dispatch(setQuickResponses([]))
    dispatch(setFeedbackStatus(false))
    dispatch(setIsEndChatModelOpen(false));
    dispatch(setChatEnded(false));
    dispatch(setAlertIsOpen(true));
    if (ageGateSet && ageGatePass) {
      setTimeout(() => {
        socket.disconnect()
      }, 1000);
    }
    dispatch(isAgeGatePassed(false))
    dispatch(isAgeGateSet(false))
    dispatch(setHideFeedback(false));
  }, [dispatch, socket, ageGateSet, ageGatePass]);

  const closeWidget = () => {
    dispatch(setDrawerIsOpen(false))
    if (chatEnded) {
      endChat();
      dispatch(setWidgetIsOpen(false));
      dispatch(setFeedbackStatus(false))
    } else {
      if(chatEnded) {
        dispatch(setWidgetIsOpen(false));
      } else {
        dispatch(setIsEndChatModelOpen(true));
      }
    }
  };

  const minimizeWidget = () => {
    if (chatEnded) {
      endChat();
      dispatch(setWidgetIsOpen(false));
      dispatch(setFeedbackStatus(false))
    } else {
       dispatch(setWidgetIsOpen(false));
    }
  };

  return (
    <div className={classes.Header}>
      <div className={classes.HeaderTitle}>
        {window.sutherland_variables.ui.header_image_url ? (
          <div className={classes.Image}>
            <img
              src={window.sutherland_variables.ui.header_image_url}
              alt='Logo'
              style={{
                width: window.sutherland_variables.ui.header_image_size,
              }}
            />
            <Trans i18nKey="header.title">
              {window.sutherland_variables.ui.header_title}
            </Trans>
          </div>
        ) : (
          <div>
            <Trans i18nKey="header.title">
              {window.sutherland_variables.ui.header_title}
            </Trans>
          </div>
        )}
        <div className={classes.HeaderButtons}>
          { ageGateSet && ageGatePass &&
            <DrawerMenu socket={socket}  restartChatHandler={endChat}/>
          }
          <button className={classes.IconButton} onClick={minimizeWidget}>
            <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>remove</div>}
              width={30}
              height={30}
              backgroundColor={'#eeeeee'} />
          </button>
          <button className={classes.IconButton} onClick={ageGateSet && ageGatePass ? closeWidget : minimizeWidget}>
            <MuiPlaceholder
              isMuiFontLoaded={muiFontLoaded}
              element={<div>close</div>}
              width={30}
              height={30}
              backgroundColor={'#e2b744'} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
