import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../../components/Avatar/Avatar';
import FileTransfer from '../MessageBubbles/FileTransfer/FileTransfer';
import Incoming from '../MessageBubbles/Incoming/Incoming';
import IncomingAgent from '../MessageBubbles/IncomingAgent/IncomingAgent';
import Outgoing from '../MessageBubbles/Outgoing/Outgoing';
import SystemMessage from '../MessageBubbles/SystemMessage/SystemMessage';
import classes from '../MessageList.module.scss';

function MessageBubble(props) {
    const { index, message, isTop, isBottom, timeStamp, socket, avatarUrl, endChat } = props
    const cards = {
        "text_bot": <Incoming message={message} isTop={isTop} isBottom={isBottom} timeStamp={timeStamp} />,
        "text_user": <Outgoing message={message} isTop={isTop} isBottom={isBottom} timeStamp={timeStamp}/>,
        "text_agent": <IncomingAgent message={message} isTop={isTop} isBottom={isBottom} timeStamp={timeStamp} />,
        "file-transfer": <FileTransfer socket={socket} url={message.url} index={index} />,
        "status": <SystemMessage message={message} />
    }

    const showAvatar = ["text_bot", "text_agent"]
    const type = (message.type === 'text') ? `text_${message.mode}` : message.type;

    if (type === "file-transfer" && endChat)
      return <></>
      const avatarSizeWidth = 40;
      const avatarSizeHeight = 19;
      const avatarSizeMaxHeight = 40;
      const avatarContainer = type !== "status" ? <div
            className={classes.avatarContainer}
            style={{ 
              minWidth: avatarSizeWidth, 
              maxHeight: avatarSizeMaxHeight, 
              marginBottom: '20px', 
              paddingTop: timeStamp === '' ? '5px' : '40px'}}
          >
            {showAvatar.includes(type) && isBottom ? (
              <Avatar
                isAgent={message.mode === "agent"}
                height={avatarSizeHeight}
                width={avatarSizeWidth}
                pictureUrl={avatarUrl}
              />
            ) : null}
          </div> : null

    return <>
      {avatarContainer}
      {cards[type]}
    </>
}

MessageBubble.propTypes = {
  index: PropTypes.number,
  message: PropTypes.object,
  isTop: PropTypes.bool,
  isBottom: PropTypes.bool,
  timeStamp: PropTypes.string,
  socket: PropTypes.object,
  avatarUrl: PropTypes.string,
  endChat: PropTypes.bool
};

export default MessageBubble;
