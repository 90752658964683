import { 
  SET_WIDGET_IS_OPEN,
  SET_DRAWER_IS_OPEN, 
  SET_AVATAR_URL, 
  SET_PRECHAT_ENABLED, 
  SET_PRECHAT_FORM_OPENED, 
  SET_AGENT_NAME,
  SET_SESSION_ID,
  SET_SOCKET_ID,
  MUI_FONT_LOADED,
  SET_RESET_CHAT,
  END_CHAT,
  SET_SESSION_START_TIME,
  FEEDBACK_STATUS,
  SET_END_CHAT_OPEN_MODEL,
  CHAT_ENDED,
  SET_HIDE_FEEDBACK,
  SET_ALERT_IS_OPEN
} from '../constants/general';

const initialState = {
  widgetIsOpen: false,
  drawerIsOpen: false,
  sessionId: '',
  socketId: '',
  prechatEnabled: false,
  prechatFormOpened: true,
  avatarUrl: '',
  agentName: '',
  muiFontLoaded: false,
  resetChat: null,
  endChat: false,
  feedbackStatus: false,
  sessionStartTime: new Date(),
  isEndChatModelOpen: false,
  chatEnded: false,
  isHideFeedback: false,
  alertIsOpen: true
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.sessionId
      };
    case SET_SOCKET_ID:
      return {
        ...state,
        socketId: action.socketId
      };
    case SET_WIDGET_IS_OPEN:
      return {
        ...state,
        widgetIsOpen: action.widgetIsOpen
      };
    case SET_DRAWER_IS_OPEN:
      const newState = {
        ...state,
        drawerIsOpen: action.drawerIsOpen
      }
      return newState;
    case SET_PRECHAT_ENABLED:
      return {
        ...state,
        prechatEnabled: action.prechatEnabled
      };

    case SET_PRECHAT_FORM_OPENED:
      return {
        ...state,
        prechatFormOpened: action.prechatFormOpened
      };

    case SET_AVATAR_URL:
      return {
        ...state,
        avatarUrl: action.avatarUrl
      };
    
    case SET_AGENT_NAME:
      return {
        ...state,
        agentName: action.agentName
      };
    
    case MUI_FONT_LOADED:
      return {
        ...state,
        muiFontLoaded: action.muiFontLoaded
      };
    
    case SET_RESET_CHAT:
      return {
        ...state,
        resetChat: action.resetChat,
        sessionStartTime: action.resetChat ? new Date() : state.sessionStartTime
      };

    case END_CHAT:
      return {
        ...state,
        endChat: action.endChat
      };

    case FEEDBACK_STATUS:
      return {
        ...state,
        feedbackStatus: action.feedbackStatus
    };
    
    case SET_SESSION_START_TIME:
      return {
        ...state,
        sessionStartTime: new Date()
      };
    
    case SET_END_CHAT_OPEN_MODEL:
      return {
        ...state,
        isEndChatModelOpen: action.isEndChatModelOpen
      };
    case CHAT_ENDED:
      return {
        ...state,
        chatEnded: action.chatEnded
      };
    case SET_HIDE_FEEDBACK:
      return {
        ...state,
        isHideFeedback: action.isHideFeedback
      };
    case SET_ALERT_IS_OPEN:
      return {
        ...state,
        alertIsOpen: action.alertIsOpen
      };
    default:
      return state;
  }
};

export default general;
