import { 
  ADD_MESSAGE,
  SET_MESSAGES,
  PATCH_MESSAGE,
  SET_QUICK_RESPONSES,
  SET_MODE,
  SET_AGENT_STATUS,
  SET_IS_TYPING,
  CANCEL_FILEUPLOADS
} from '../constants/messages';

const initialState = {
  messages: [],
  quickResponses: [],
  mode: 'bot', // Possible values: bot, user, agent
  agentStatus: '', // Possible values: available, unavailable, session-ended, is-typing, stop-typing
  typing: false
};

const messages = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGES:
      return {
        ...state,
        messages: action.messages
      };

    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.message]
      };

    case PATCH_MESSAGE:
      const messageList = [...state.messages];
      messageList[action.message.index][action.message.key] =  action.message.value
      return {
        ...state,
        messages: [...messageList]
      };
    
    case SET_QUICK_RESPONSES:
      return {
        ...state,
        quickResponses: action.quickResponses
      };
      
    case SET_MODE:
      return {
        ...state,
        mode: action.mode
      };
    
    case SET_AGENT_STATUS:
      return {
        ...state,
        agentStatus: action.agentStatus
      };
    
    case SET_IS_TYPING:
      return {
        ...state,
        typing: action.typing
      };

    case CANCEL_FILEUPLOADS:
      let messagesList = [...state.messages];
      for (let i = messagesList.length - 1; i >= 0; i--) {
        if (messagesList[i].type === 'file-transfer') {
          messagesList[i]["parameters"] = { fileTransfer: true };
        }
      }

      return {
        ...state,
        messages: messagesList
      };
      
      
    default:
      return state;
  }
};

export default messages;
