import React from 'react';
import classes from './EndChatModal.module.scss';
import Modal from '../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setIsEndChatModelOpen, setChatEnded } from '../../store/actions/general';
const EndChatModal = ({socket, isOpen, closeHandler}) => {
    const dispatch = useDispatch();
    const { ageGateSet, ageGatePass } = useSelector(state => state.authentication);
    const endChat = () => {
        if (ageGateSet && ageGatePass) {
            setTimeout(() => {
              dispatch(setIsEndChatModelOpen(false));
              socket.emit("end-chat", {})
              dispatch(setChatEnded(true));
            }, 1000);
          }
    }
    return (
        <Modal isOpen={isOpen} onClose={closeHandler} contentWidth={'60%'} top={'0'} contentHeight={'100%'}>
            <div className={classes.EndChatModalContainer}>
                <h2>End chat</h2>
                <div className={classes.EndChatModalContent}>
                    <div className={classes.Label}>Are you sure you want to end this chat session?</div>
                    <div className={classes.Buttons}>
                        <div><button className={classes.ButtonEnd} onClick={endChat}>End Chat</button></div>
                        <div><button className={classes.ButtonCancel} onClick={closeHandler}>Cancel</button></div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default EndChatModal;
