import React from 'react';
import classes from './Modal.module.scss';

const Modal = ({ isOpen, onClose, disableMask, children, contentWidth, top, contentHeight }) => {
  if (!isOpen) return null;

  return (
    <div className={classes.ModalContainer} style={{top: top, height: contentHeight}}>
        {!disableMask && <div className={classes.ModalMask} onClick={onClose}></div>}
        <div className={classes.ModalContent} style={{width: contentWidth}}>
            {children}
        </div>
    </div>
  );
};

export default Modal;