import React, { useState, useEffect, useRef } from 'react';
import io from "socket.io-client";
import classes from './MainContainer.module.scss';
import Header from '../../components/Header/Header';
import AgeGateModal from '../../components/AgeGateModal/AgeGateModal';
import MessageComposer from '../../components/MessageComposer/MessageComposer';
import MessageList from '../MessageList/MessageList';
import Typing from '../Typing/Typing';
import Alert from "../../components/Alert/Alert";
import QuickResponseContainer from '../QuickResponseContainer/QuickResponseContainer';
import Message from '../../models/Message';
import QuickResponse from '../../models/QuickResponse';
import { setSessionId, setAgentName, setResetChat, setEndChat, setIsEndChatModelOpen, setHideFeedback, setFeedbackStatus,setChatEnded } from '../../store/actions/general';
import { addMessage, cancelFileUploads, setQuickResponses, setMode, setIsTyping } from '../../store/actions/messages';
import EndChatModal from '../../components/EndChatModal/EndChatModal';
import { useDispatch, useSelector } from 'react-redux';

const MainContainer = () => {
  const { ageGatePass, ageGateSet } = useSelector(state => state.authentication);
  const { prechatEnabled, widgetIsOpen, sessionId, resetChat, endChat, isEndChatModelOpen, isHideFeedback } = useSelector(state => state.general);
  const { messages, typing } = useSelector(state => state.messages);
  const [isLoading, setIsLoading] = useState(true);
  const socketRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    window.sutherland_variables.resetChat = resetChat;
    window.sutherland_variables.ageGateSet = ageGateSet;
    window.sutherland_variables.ageGatePass = ageGatePass;
    window.sutherland_variables.sessionId = sessionId;
    
    if (!socketRef.current && ageGateSet && ageGatePass) {
      let socket = io(window.sutherland_variables.engineBaseUrl, {
        path: '/ws/chat/',
        transports: ['websocket', 'polling'],
        upgrade: true,
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelayMax: 2000, 
        randomizationFactor: 0.5,
        auth: {
          bot_id: window.sutherland_variables.botId, // The bot config ID
          session_id: !window.sutherland_variables.sessionId ? sessionId : window.sutherland_variables.sessionId
        },
        query: {
          bot_id: window.sutherland_variables.botId, // The bot config ID
          session_id: !window.sutherland_variables.sessionId ? sessionId : window.sutherland_variables.sessionId
        }
      });
      
      socketRef.current = socket;
      
      socket.on('connected', (data) => {
        const svars = window.sutherland_variables;
        if (svars.ageGateSet && svars.ageGatePass) {
          if (svars.resetChat || svars.resetChat === null) {
            console.log('\u25B6 initialize session');
            if (!window.sutherland_variables.sessionId) {
              dispatch(setSessionId(data.session_id));
              socketRef.current.auth.session_id = data.session_id;
              socketRef.current._opts.query.session_id = data.session_id;
            }
            // Auto send message to start convo
            console.log('\u25B6 start chat');
            socket.emit("send",  { 
              "name": "welcome-event", 
              "parameters":
                {
                  "UserAgent": navigator.userAgent,
                  // eslint-disable-next-line no-restricted-globals
                  "ScreenResolution": `${screen.width}x${screen.height}`
                }
            });
            dispatch(setResetChat(false))
          }
        } else {
          socket = null;
        }
      });

      socket.on('disconnected', (data) => {
        console.log('Client disconnected.')
      });

      socket.on('message-received', (data) => {      
        data.mode = data.mode.toLowerCase()
        dispatch(setMode(data.mode))
        dispatch(setIsTyping(false))
        for (const message of data.payload) {
          message.mode = data.mode
          switch(message.type.toLowerCase()) {
            case 'chips':
              const responsesList = [];
              for (const option of message.options) {
                const newQuickResponse = new QuickResponse(option);
                responsesList.push(newQuickResponse);
              }
              dispatch(setQuickResponses(responsesList));
              break;
            case 'set_csat_survey_false':
              dispatch(setHideFeedback(true));
              break;
            case 'set_csat_survey_true':
              dispatch(setHideFeedback(false));
              break;
            case 'info': 
              if (data.mode === "agent") {
                dispatch(setAgentName(data.username));
              }
              message.text = `[${message.title}](${message.link})`;
              dispatch(addMessage(new Message(message)));
              break;
            case 'status': 
              dispatch(addMessage(new Message(message)));     
              switch (message.event) {
                case "ChatEnd":
                  dispatch(setEndChat(true)); 
                  dispatch(setChatEnded(true));
                  socket.disconnect();
                  break;
                case "FileRequestCancel":
                  dispatch(cancelFileUploads())
                  break;
                default:
                  break;
              }
              break;
            default:
              if (message.type !== 'error' && message.type !== 'liveAgentHandoff') {
                if (data.mode === "agent") {
                  dispatch(setAgentName(data.username));
                }
                dispatch(addMessage(new Message(message)));
              }
              break;
          }
        }
      });

      socket.on('agent-typing', () => {
        dispatch(setMode("agent"))
        dispatch(setIsTyping(true))
        setTimeout(() => {
          dispatch(setIsTyping(false))  
        }, 1500)
      });

      socket.on('stop-typing', () => {
        dispatch(setMode("agent"))
        dispatch(setIsTyping(false))
      });
      
      socket.on('file-transfer', (data) => {
        dispatch(setIsTyping(false))
        const message = {
          "type": "file-transfer",
          "text": "",
          "url": data?.url,
        }
        dispatch(addMessage(new Message(message)));     
      });

      setIsLoading(false)
    } else if (!ageGateSet) {
      socketRef.current = null;
      setIsLoading(false)
    }
  }, [dispatch, sessionId, messages, resetChat, ageGateSet, ageGatePass, isHideFeedback]);

  useEffect(()=>{
    if(isHideFeedback && endChat) {
      dispatch(setFeedbackStatus(true));
    }
  },[dispatch, endChat, isHideFeedback])
  const handleCloseEndchatModal = () => {
    dispatch(setIsEndChatModelOpen(false));
  }

  if (!isLoading) {
    return (
      <div className={prechatEnabled || widgetIsOpen ? classes.MainContainer : classes.MainContainerGrowIn}>
        <Header socket={socketRef.current}/>
        
        <EndChatModal isOpen={isEndChatModelOpen} closeHandler={handleCloseEndchatModal} socket={socketRef.current} />
        {!ageGateSet && <AgeGateModal isOpen={true} />}
        {ageGateSet && ageGatePass ? ( 
          <>
          <Alert socket={socketRef.current}/>
          
          <div className={classes.SessionContainer}>
            <MessageList  socket={socketRef.current} />        
            {!endChat && 
              <>
                
                <QuickResponseContainer socket={socketRef.current}/>
                <div className={classes.typeComposerSection}>
                  <Typing show={typing} mode="dot-loader" typingText="" />
                  <div className={classes.footer}>
                    <MessageComposer socket={socketRef.current}/>
                  </div>
                </div>
              </>
           }
          </div>
          </>
        ) : null}
      </div>
    );
  }
  return <div></div>
};

export default MainContainer;
