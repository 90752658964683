import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { 
  SET_USER_ID, 
  SET_FINGERPRINT_ID,
  SET_AGE_GATE_SET,
  SET_AGE_GATE_PASS
} from '../constants/authentication';

export const setUserId = (customerId) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_ID,
      customerId
    });
  };
};

export const loadFingerprint = () => {
  return (dispatch) => {
    FingerprintJS.load()
      .then((fingerprint) => fingerprint.get())
      .then((fingerprint) => {
        dispatch({
          type: SET_FINGERPRINT_ID,
          fingerprintId: fingerprint.visitorId
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_FINGERPRINT_ID,
          fingerprintId: null
        });
      });
  };
};

export const isAgeGateSet = (isSet) => {
  return (dispatch) => {
    dispatch({
      type: SET_AGE_GATE_SET,
      ageGateSet: isSet
    });
  };
};

export const isAgeGatePassed = (passing) => {
  return (dispatch) => {
    dispatch({
      type: SET_AGE_GATE_PASS,
      ageGatePass: passing
    });
  };
};
