import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classes from './MessageComposer.module.scss';
import Text from '../controls/Text/Text';
import Message from '../../models/Message';
import { addMessage, setQuickResponses } from '../../store/actions/messages';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

const MessageComposer = ({socket}) => {
  const { t } = useTranslation();
  const { ageGatePass, ageGateSet } = useSelector(state => state.authentication);
  const { sessionId } = useSelector(state => state.general);
  const { mode } = useSelector(state => state.messages);
  const [newMessage, setNewMessage] = useState('');
  const debouncedInputValue = useDebounce(newMessage, 500);
  const [isTypingState, setisTypingState] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const [multiLineStart, setMultiLineStart] = useState(false);
  const [composerRef, setComposerRef] = useState(null);
  
  const dispatch = useDispatch()
  const send = (textareaRef = null) => {
    if (newMessage.trim() !== '') {
      socket.emit("send", {
        message: newMessage,
        session_id: sessionId,
        mode: mode,
        messenger_id: window.sutherland_variables.botId
      });
      if(textareaRef && textareaRef.current) { 
        textareaRef.current.style.height = 'inherit';
        setLimitReached(false);
      } else {
        composerRef.current.style.height = 'inherit';
        setLimitReached(false);
      }
      const message = {
        "text": newMessage,
        "mode": "user"
      }
      dispatch(addMessage(new Message(message)));
      dispatch(setQuickResponses([]));
      setNewMessage('')
      setisTypingState(false);
    }
  };

  const handleTypingStart = (message, textareaRef = null) => {
    setNewMessage(message)
    if(textareaRef) { 
      textareaRef.current.style.height = 'inherit';
      setMultiLineStart(false);
      if(textareaRef.current.scrollHeight > 30) {
        setMultiLineStart(true);
      }
      if(message.length === 250) {
        setLimitReached(true);
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 20}px`;
      } else if (message.length === 0) {
        textareaRef.current.style.height = 'inherit';
        setLimitReached(false);
      } else {
        setLimitReached(false);
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
      setComposerRef(textareaRef);
    }
    if (!isTypingState) {
      setisTypingState(true);
      socket.emit('is-typing', {})
    }
  };

  const stopTyping = useCallback(() => {
    socket.emit('stop-typing', {})
    setisTypingState(false);
  }, [socket]);

  useEffect(() => {
    if (newMessage === debouncedInputValue && isTypingState && newMessage !== '') {
      stopTyping()
    }
  }, [debouncedInputValue, newMessage, isTypingState, stopTyping]);

  if (ageGateSet && ageGatePass) {
    return (
      <div className={`${classes.MessageComposer} ${multiLineStart ? classes.composerBaseline : ''}`}>
        <div className={`${classes.textContainer} ${limitReached ? classes.limitReached : ''}`}>
          <Text
            value={newMessage}
            onChange={handleTypingStart}
            placeholder={t('composer.placeholder', 'Type your message here...')}
            onEnter={send}
            name='message'
            maxLength={250}
          />
           {limitReached && <p className={classes.error}>250-character limit reached</p> }
        </div>

        <button className={classes.Send} onClick={send}>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="24" cy="24" r="24" />
              <path d="M16 24L17.41 25.41L23 19.83V32H25V19.83L30.58 25.42L32 24L24 16L16 24Z" fill="white"/>
            </svg>
        </button>
      </div>
    );
  }
  return (<div></div>)
};

export default MessageComposer;