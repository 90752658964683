import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './AgeGateModal.module.scss';
import Modal from '../Modal/Modal';
import CheckBox from '../controls/CheckBox/CheckBox';
import { useDispatch } from 'react-redux';
import { isAgeGateSet, isAgeGatePassed } from '../../store/actions/authentication';

const AgeGateModal = ({isOpen, closeHandler}) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        isUSResident: false,
        is16Above: false,
        errorResidency: false,
        errorAge: false,
      });
    
    const dispatch = useDispatch();

    const setErrorMessage = (message, hasError) => {
        if (hasError) {
            return (
                <div className={classes.InputError}>{message}</div>
            )
        }
        return null;
    }

    const startChatting = () => {
        const { is16Above, isUSResident } = formData;

        if(is16Above && isUSResident) {
            dispatch(isAgeGatePassed(true));
            dispatch(isAgeGateSet(true));
        } else {
            setFormData({
                ...formData,
                errorAge: !is16Above,
                errorResidency: !isUSResident,
            });
        }
      };

    return (
        <Modal isOpen={isOpen} onClose={closeHandler} disableMask={true} contentWidth={'90%'}>
            <div className={classes.AgeGateModalContainer}>
                <div>
                    <div className={classes.Label}>Hi! Before we begin chatting, I need to confirm a few details:</div>
                    <div className={classes.AgeGateModalContent}>
                        <div className={classes.ResidencyStatus}>
                            <CheckBox 
                                label="I am above the age of 16"
                                checked={formData.is16Above}
                                onChange={() => setFormData({ ...formData, is16Above: !formData.is16Above })}
                                required={true}
                            />
                        </div>
                        {setErrorMessage("We’re sorry but our Marketing Code means we’re unable to talk to you if you’re under 16. Please ask your parent or guardian to help you.", formData.errorAge)}
                        <div className={classes.ResidencyStatus}>
                            <CheckBox 
                                label="I am a resident of the United States"
                                checked={formData.isUSResident}
                                onChange={() => setFormData({ ...formData, isUSResident: !formData.isUSResident })}
                                required={true}
                            />
                        </div>
                        {setErrorMessage("You must be a resident of the United States to chat with an agent.", formData.errorResidency)}
                        <div className={classes.AgeGateDetail}>
                            <div dangerouslySetInnerHTML={{
                                    __html: t('prechat.description', 'All chat conversations are processed and retained by Mars and its vendors subject to our <a href="https://www.mars.com/privacy" target="_blank" rel="noopener noreferrer"><u>Privacy Statement</u></a> and <a href="https://www.mars.com/legal" target="_blank" rel="noopener noreferrer"><u>Terms of Use</u></a>. By continuing to use the chat feature, you consent to the retention of your conversation and confirm that you have read our Privacy Statement and Terms of Use.')
                                }}
                            />
                        </div>
                        <div className={classes.Buttons}>
                            <button onClick={startChatting}>Start Chatting</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AgeGateModal;
